import { graphql, useStaticQuery } from "gatsby"

export const useSiteStrapiCareers = () => {
    const data: any = useStaticQuery(graphql`
        query {
            strapiCareer {
                openAppl
                position1
                position2
            }
        }
    `)

    return data.strapiCareer;

}