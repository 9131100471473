import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import DefaultAppView from '../app-view/default-app-view';
import Footer from '../components/footer';
import Header from '../components/header';
import { SEO } from '../components/seo';
import { getTheme, isMobile } from '../functions/theme';
import { useSiteStrapiCareers } from '../hooks/site-strapi-careers';

const Careers = () => {

    const currentTheme: string = getTheme();
    const _isMobile: boolean = isMobile();
    
    const strapiQuery: any = useSiteStrapiCareers();
    
    const footerQuery: any = useStaticQuery(query);
    const address: string = footerQuery.strapiFooter.address;

    const currenteTheme: 'light' | 'dark' = getTheme()

    return (
        <DefaultAppView>
            <Header currentPageTheme={currentTheme}/>
            <div className={`flex flex-col w-screen h-screen px-5 lg:px-36 pt-28 pb-16 lg:pt-40 ${currentTheme === 'light' ? 'bg-cardinal-white text-black' : 'bg-black text-cardinal-white'}`}>
                <div className='hidden capitalize lg:text-lg lg:flex'>careers</div>
                <div className='flex flex-col w-full h-full lg:flex-row lg:pt-7'>
                    <div className='flex flex-col w-full lg:h-full lg:w-2/5 lg:justify-between'>
                        <span className='w-1/2 text-3xl capitalize lg:text-6xl'>open positions</span>
                        <div className='flex flex-col w-1/2 pt-2 lg:pt-0'>
                            <span className='pb-1 text-[8px] uppercase lg:pb-7 lg:text-base'>office address</span>
                            <span className='text-xs capitalize lg:text-xl'>
                                {address}
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col justify-between w-full h-full pt-8 text-xl capitalize lg:text-2xl lg:w-3/5 lg:pt-0'>
                        <div className='flex flex-col w-full'>
                            <div className={`flex flex-row items-center justify-between w-full h-16 lg:h-32 border-y-[1px] group ${ currentTheme === 'light' ? 'border-black' : 'border-cardinal-white' }`}>
                                <Link to='/careers' className='flex flex-row justify-between w-full'>
                                    {/* <span className={`cursor-pointer undeline-animation ${currentTheme == 'dark' && 'undeline-animation-white'}`}>
                                        {strapiQuery?.position1 && strapiQuery.position1}
                                    </span> */}
                                    <span className={`transition-[background-size] ease-in-out duration-200 cursor-pointer bg-[length:0%_0.1em] group-hover:bg-[length:100%_0.1em] group-focus:bg-[length:100%_0.1em] group-active:bg-[length:100%_0.1em] bg-no-repeat bg-left-bottom
                                            ${currentTheme == 'dark' ? 
                                                'bg-gradient-to-r from-cardinal-white to-cardinal-white' : 
                                                'bg-gradient-to-r from-black to-black'}`}>
                                        {strapiQuery?.position1 && strapiQuery.position1}
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={_isMobile ? '8' : '16'} height={_isMobile ? '12' : '28'} viewBox="0 0 17.796 28.858">
                                        <path id="_Color" data-name=" ↳Color" d="M3.386,0,0,3.391,11,14.429,0,25.468l3.386,3.391L17.8,14.429Z" fill={currentTheme === 'light' ? '#222' : '#F5F4F5'}/>
                                    </svg>
                                </Link>
                            </div>
                            <div className={`flex flex-row items-center justify-between w-full h-16 lg:h-32 border-b-[1px] ${ currentTheme === 'light' ? 'border-black' : 'border-cardinal-white' }`}>
                                <Link to='/careers' className='flex flex-row justify-between w-full group'>
                                    {/* <span className={`cursor-pointer undeline-animation ${currentTheme == 'dark' && 'undeline-animation-white'}`}>
                                        {strapiQuery?.position2 && strapiQuery.position2}
                                    </span> */}
                                    <span className={`transition-[background-size] ease-in-out duration-200 cursor-pointer bg-[length:0%_0.1em] group-hover:bg-[length:100%_0.1em] group-focus:bg-[length:100%_0.1em] group-active:bg-[length:100%_0.1em] bg-no-repeat bg-left-bottom
                                            ${currentTheme == 'dark' ? 
                                                'bg-gradient-to-r from-cardinal-white to-cardinal-white' : 
                                                'bg-gradient-to-r from-black to-black'}`}>                                        
                                        {strapiQuery?.position2 && strapiQuery.position2}
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={_isMobile ? '8' : '16'} height={_isMobile ? '12' : '28'} viewBox="0 0 17.796 28.858">
                                        <path id="_Color" data-name=" ↳Color" d="M3.386,0,0,3.391,11,14.429,0,25.468l3.386,3.391L17.8,14.429Z" fill={currentTheme === 'light' ? '#222' : '#F5F4F5'}/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className={`flex flex-row items-center justify-between w-full h-16 lg:h-32 border-y-[1px] ${ currentTheme === 'light' ? 'border-black' : 'border-cardinal-white' }`}>
                            <Link to='/careers' className='flex flex-row justify-between w-full group'>
                                {/* <span className={`cursor-pointer undeline-animation ${currentTheme == 'dark' && 'undeline-animation-white'}`}>
                                    {strapiQuery?.openAppl && strapiQuery.openAppl}
                                </span> */}
                                <span className={`transition-[background-size] ease-in-out duration-200 cursor-pointer bg-[length:0%_0.1em] group-hover:bg-[length:100%_0.1em] group-focus:bg-[length:100%_0.1em] group-active:bg-[length:100%_0.1em] bg-no-repeat bg-left-bottom
                                            ${currentTheme == 'dark' ? 
                                                'bg-gradient-to-r from-cardinal-white to-cardinal-white' : 
                                                'bg-gradient-to-r from-black to-black'}`}>
                                    {strapiQuery?.openAppl && strapiQuery.openAppl}
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" width={_isMobile ? '8' : '16'} height={_isMobile ? '12' : '28'} viewBox="0 0 17.796 28.858">
                                    <path id="_Color" data-name=" ↳Color" d="M3.386,0,0,3.391,11,14.429,0,25.468l3.386,3.391L17.8,14.429Z" fill={currentTheme === 'light' ? '#222' : '#F5F4F5'}/>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </DefaultAppView>
    )

}

export const Head = () => (
    <SEO title='Cardinal - Supersecret Careers'/>
)

export default Careers;

export const query = graphql`
    query {
        strapiFooter {
            address
            companyName
            phoneNumber
        }
    }
`;